const INITIAL_STATE = {
  count: 0,
  videos: [],
  loading: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_VIDEOS": {
      return {
        ...state,
        videos: action.videos,
        count: action.count,
        loading: false
      }
    }
    default:
      return state
  }
}
