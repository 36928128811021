const INITIAL_STATE = {
    articles: [],
    count: 0,
    category: [],
    loading: false
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "GET_ARTICLES": {
        return {
          ...state,
          articles: action.articles,
          count: action.count,
          loading: false
        }
      }
      case "GET_ARTICLE_CATEGORY": {
        return {
          ...state,
          category: action.category,
          loading: false
        }
      }
      default:
        return state
    }
  }
  