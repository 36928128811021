import moment from "moment"
const INITIAL_STATE = {
    userPosts: [],
    loading: false
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "GET_USER_POSTS": {
        const userPosts = action.userPosts.map((val) => {
          return {
            _id: val._id,
            post: val.post,
            user: val.user.first_name,
            comment: val.comment,
            createdAt: moment(val.createdAt).format('DD/MM/YYYY')
          }
        })
        return {
          ...state,
          userPosts,
          loading: false
        }
      }
      default:
        return state
    }
  }
  