const INITIAL_STATE = {
    count: 0,
    questions: [],
    loading: false
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "GET_QUESTIONS": {
        return {
          ...state,
          questions: action.questions,
          count: action.count,
          loading: false
        }
      }
      default:
        return state
    }
  }
  