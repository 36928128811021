const INITIAL_STATE = {
    data: [],
    loading: false
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "GET_COMMUNITY_DASHBOARD": {
        return {
          ...state,
          data: action.data,
          loading: false
        }
      }
      default:
        return state
    }
  }
  