const INITIAL_STATE = {
  magazines: [],
  count: 0,
  loading: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_MAGAZINES": {
      const count = action.count
      const magazines = action.magazines.map((val) => {
        return {
          _id: val._id,
          title: val.title,
          article_no: val.articles.length,
          createdAt: val.createdAt,
          updatedAt: val.updatedAt
        }
      })
      return {
        ...state,
        magazines,
        count,
        loading: false
      }
    }

    case "GET_MAGAZINES_BY_ID": {
      const magazines = action.magazines.articles.map((val) => {
        return val._id
      })
      const selectedArticles = action.magazines.articles.map((val) => {
        return {
          label: val.title,
          value: val._id
        }
      })
      return {
        ...state,
        magazines: { articles: magazines, title: action.magazines.title, selectedArticles },
        loading: false
      }
    }

    default:
      return state
  }
}
