const INITIAL_STATE = {
    onlineEvents: [],
    count: 0,
    loading: false
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "GET_ONLINE_EVENTS": {
        return {
          ...state,
          onlineEvents: action.onlineEvents,
          count: action.count,
          loading: false
        }
      }
      default:
        return state
    }
  }
  