const INITIAL_STATE = {
    rsvp: [],
    count: 0,
    loading: false
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "GET_RSVP": {
        return {
          ...state,
          rsvp: action.data,
          count: action.count,
          loading: false
        }
      }
      default:
        return state
    }
  }
  