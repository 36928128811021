const INITIAL_STATE = {
    categories: [],
    category: {},
    count: 0,
    loading: false
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "GET_CATEGORIES": {
        return {
          ...state,
          categories: action.data,
          count: action.count,
          loading: false
        }
      }
      case "GET_CATEGORY": {
        return {
          ...state,
          category: action.data,
          loading: false
        }
      }
      default:
        return state
    }
  }
  