const INITIAL_STATE = {
  badges: [],
  count: 0,
  assigned: [],
  assignedCount: 0,
  loading: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_BADGES": {
      return {
        ...state,
        badges: action.badges,
        count: action.count,
        loading: false
      }
    }
    case "GET_ASSIGNED_BADGES": {
      return {
        ...state,
        assigned: action.data,
        assignedCount: action.count,
        loading: false
      }
    }
    default:
      return state
  }
}
