const INITIAL_STATE = {
  users: [],
  count: 0,
  loading: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_USERS": {
      return {
        ...state,
        users: action.users,
        count: action.count,
        loading: false
      }
    }
    default:
      return state
  }
}
