const INITIAL_STATE = {
    contacts: [],
    count: 0,
    category:[],
    loading: false
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "GET_CONTACTS": {
        return {
          ...state,
          contacts: action.data,
          count: action.count,
          loading: false
        }
      }
      case "GET_CONTACT_CATEGORY": {
        return {
          ...state,
          category: action.category,
          loading: false
        }
      }
      default:
        return state
    }
  }
  