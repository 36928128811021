const INITIAL_STATE = {
    static: {},
    loading: false
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "GET_STATIC": {
        return {
          ...state,
          static: action.data,
          loading: false
        }
      }
      default:
        return state
    }
  }
  