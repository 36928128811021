const INITIAL_STATE = {
    states: [],
    state: {},
    count: 0,
    loading: false
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "GET_STATES": {
        return {
          ...state,
          states: action.data,
          count: action.count,
          loading: false
        }
      }
      case "GET_STATE": {
        return {
          ...state,
          state: action.data,
          loading: false
        }
      }
      default:
        return state
    }
  }
  