import { combineReducers } from 'redux'
import articles from './articles/articles'
import articleComment from './articles/articleComment'
import audios from './audio/audio'
import auth from './auth'
import categories from './categories'
import contacts from './contacts'
import contactCategories from './contactCategories'
import dashboard from './dashboard'
import filters from './filters'
import navbar from './navbar'
import layout from './layout'
import language from './languages'
import appUsage from './reports/appUsage'
import videos from './video/video'
import users from './users/users'
import magazines from './magazines/magazines'
import rsvp from './rsvp'
import onlineEvents from './onlineEvents/onlineEvents'
import offlineEvents from './offlineEvents/offlineEvents'
import points from './points'
import userPosts from './userPosts/userPosts'
import badges from './badges/badges'
import quiz from './quiz/quiz'
import questions from './questions/questions'
import reports from './reports/report'
import reportContent from './reports/reportContent'
import feedback from './reports/feedback'
import feeds from './feed/feed'
import feedComment from './feed/feedComment'
import userEngagement from './reports/userEngagement'
import referral from './reports/referral'
import slides from './slides'
import states from './states'
import staticData from './static'

const rootReducer = combineReducers({
  articles,
  articleComment,
  audios,
  auth,
  appUsage,
  categories,
  contacts,
  contactCategories,
  dashboard,
  filters,
  layout,
  language,
  navbar,
  feedback,
  feeds,
  feedComment,
  magazines,
  onlineEvents,
  offlineEvents,
  points,
  badges,
  quiz,
  questions,
  referral,
  reports,
  reportContent,
  rsvp,
  userPosts,
  users,
  userEngagement,
  videos,
  slides,
  states,
  staticData
})

export default rootReducer