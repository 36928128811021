const INITIAL_STATE = {
  count: 0,
  audios: [],
  loading: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_AUDIOS": {
      return {
        ...state,
        audios: action.audios,
        count: action.count,
        loading: false
      }
    }
    default:
      return state
  }
}
